<template>
  <div class="footer-wrapper">
    <!--    <div v-if="isMobile"
             class="ml-4 text-[18px]">
          <NuxtLink to="/"
                    class="cursor-pointer mb-[30px] flex">
            <NuxtImg format="webp"
                     quality="80"
                     loading="lazy"
                     width="200"
                     src="/logo/osn-zh-horizon.png"
                     alt="logo"/>
          </NuxtLink>
          <NuxtLink :to="faceBookUrl"
                    target="_blank"
                    class="mb-4 flex items-center">
            <svg-icon
                class="mr-3 cursor-pointer"
                name="fb"
                size="30"
            />
            空間便利店粉絲專頁
          </NuxtLink>
          <NuxtLink :to="lineUrl"
                    target="_blank"
                    class="mb-4 flex items-center">
            <svg-icon
                class="mr-3 cursor-pointer"
                name="line"
                size="30"
            />
            場地主問題專用 Line
          </NuxtLink>
          <NuxtLink :to="clientLineUrl"
                    target="_blank"
                    class="flex items-center">
            <svg-icon
                class="mr-3 cursor-pointer"
                name="line"
                size="30"
            />
            預約問題專用 Line
          </NuxtLink>
        </div>-->
    <div>
      <div class="mb-5 mobile:ml-4 mobile:text-[18px]">
        <NuxtLink to="/"
                  class="cursor-pointer mb-[30px] flex">
<!--          <NuxtImg format="webp"
                   quality="80"
                   loading="lazy"
                   :width="isMobile?180:200"
                   src="/logo/osn-zh-horizon.png"
                   alt="logo"/>-->
          <img class="logo"
               :width="isMobile?180:200"
               src="@/assets/image/logo/osn-zh-horizon.png"
               alt="logo">
        </NuxtLink>
        <NuxtLink :to="faceBookUrl"
                  target="_blank"
                  class="mb-4 flex items-center">
          <svg-icon
              class="mr-3 cursor-pointer"
              name="fb"
              size="30"
          />
          FB 粉絲專頁
        </NuxtLink>
        <NuxtLink :to="lineUrl"
                  target="_blank"
                  class="mb-4 flex items-center">
          <svg-icon
              class="mr-3 cursor-pointer"
              name="line"
              size="30"
          />
          場地主聯絡專用 Line
        </NuxtLink>
        <NuxtLink :to="clientLineUrl"
                  target="_blank"
                  class="flex items-center">
          <svg-icon
              class="mr-3 cursor-pointer"
              name="line"
              size="30"
          />
          找場地專用 Line
        </NuxtLink>
      </div>
      <ul class="sub-menu">
        <li>
          <NuxtLink to="/blog"
                    target="_blank">
            {{ $t('article.space_article') }}
          </NuxtLink>
        </li>
        <li>
          <NuxtLink to="/host/homepage"
                    target="_blank">
            {{ $t('menu.function_introduction') }}
          </NuxtLink>
        </li>
        <li>
          <NuxtLink to="/host/plan"
                    target="_blank">
            {{ $t('menu.owner_index') }}
          </NuxtLink>
        </li>
        <li>
          <NuxtLink to="/host/qa"
                    target="_blank">
            {{ $t('menu.help_center') }}
          </NuxtLink>
        </li>
        <!--        <li>
                  <NuxtLink :to="lineUrl"
                            target="_blank">
                    {{ $t('menu.line') }}
                  </NuxtLink>
                </li>-->
        <li>
          <NuxtLink :to="privacyUrl"
                    target="_blank">
            {{ $t('menu.privacy_policy') }}
          </NuxtLink>
        </li>
        <li>
          <NuxtLink :to="clientUrl"
                    target="_blank">
            {{ $t('menu.contract_terms') }}
          </NuxtLink>
        </li>
        <li>
          <NuxtLink :to="hostTermsUrl"
                    target="_blank">
            {{ $t('menu.host_terms') }}
          </NuxtLink>
        </li>
      </ul>
      <ul class="company">
        <li>Copyright © 2024 One Step Technology Limited Company. All Rights Reserved.</li>
        <li>一步科技有限公司 統一編號：90041170</li>
      </ul>
    </div>

    <!-- 佔位用為了pc:justify-around -->
    <div></div>
  </div>
</template>
<script setup lang="ts">
import {computed, ref} from 'vue'

const settingStore = useSettingStore()
const isMobile = computed(() => settingStore.isMobile);

const helpUrl = ref(import.meta.env.VITE_APP_BASE_HELP_URL)
const lineUrl = ref(import.meta.env.VITE_APP_BASE_LINE_URL)
const clientLineUrl = ref(import.meta.env.VITE_APP_BASE_CLIENT_LINE_URL)
const clientUrl = ref(import.meta.env.VITE_APP_BASE_CLIENT_URL)
const hostTermsUrl = ref(import.meta.env.VITE_APP_BASE_TERMS_URL)
const privacyUrl = ref(import.meta.env.VITE_APP_BASE_PRIVACY_URL)
const faceBookUrl: string = import.meta.env.VITE_APP_BASE_FACEBOOK_URL;


</script>

<style lang="scss"
       scoped>
.footer-wrapper {
  @apply flex border-t
  pc:pt-[50px] pc:pb-[45px] pc:justify-around
  mobile:mb-[25px] mobile:flex-col;

  > div {
    @apply mt-[20px];
  }

  ul.sub-menu {
    @apply flex
    pc:justify-between
    mobile:px-[30px] mobile:flex-wrap mobile:justify-start mobile:text-center;

    li {
      @apply pc:mr-3 mobile:mb-[20px] mobile:w-1/3;
    }
  }

  ul.company {
    @apply text-[12px] text-osn-dark-grey-43
    pc:mt-[19px];
    li {
      @apply mb-[8px]
      mobile:px-[35px] mobile:text-center;
    }

    li:last-child {
      @apply m-0;
    }
  }

}
</style>
